'use client';

import { signIn } from 'next-auth/react';

export const SignInButton = () => (
  <button
    type="button"
    className="rounded-md bg-green px-10 py-2 text-sm font-semibold text-gray-12 shadow-sm ring-1 ring-inset ring-green"
    onClick={() => signIn('cognito')}
  >
    Sign in
  </button>
);
